.BoxText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 20px;
    padding-bottom: 3%;
    min-width: 100%;
    text-align: center;
    border: 1px solid rgba(9,9,9,.1);
}
.GridOfferta{
    display: grid;
    grid-template-columns: 1fr 1fr 5%;
    margin-top: 10px;
    width: 100%;
    height: 5vh;
    padding: 2%;
    border: 1px solid rgba(9,9,9,.1);

}
.BoxText:nth-of-type(3n){
    border-left: 0;
}
.BoxText:nth-of-type(3n-1){
    border-right: 0;
}
#Attivazione{
    visibility: hidden;
}
.GridOffertaMain, .GridOffertaMain2{
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr 5%;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
    transition: height 0.5s;
}
.FlexDati{
    width: 100%;
    display: flex;
    width: 100%;
    height: auto;
    border: 2px solid var(--orange);
    border-radius: 28px;
    flex-direction: column;
    padding: 1%;
}
#Prefisso_Offerta, #Migrazione_Offerta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 10px;
    padding-top: 5px;
}
#Migrazione_Offerta{
    width: 160%;
    align-items: center;
}
#Prefisso_Offerta{
    width: 40%;
}
#Comune_Offerta{
    width: 60%;
}
#Prefisso_Offerta{
    width: 100%;
    display: flex;
    justify-content: left;
}
.GridOffertaMain > :first-child, .GridOffertaMain2 > :first-child{
    border-radius: 28px 0 0 0;
}
.GridOffertaMain > :last-child, .GridOffertaMain2 > :last-child{
    border-radius: 0 0 28px 0;
}
.GridOffertaMain > :nth-child(3), .GridOffertaMain2 > :nth-child(3){
    border-radius: 0 28px 0 0;
}
.GridOffertaMain > :nth-last-child(3), .GridOffertaMain2 > :nth-last-child(3){
    border-radius: 0 0 0 28px;
}

.RowOfferta{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 20px;
}
.CheckOfferta{
    scale: 1.2;
    margin-right: 5px;
}
.FirstText{
    font-size: 18;
    font-weight: bold;
}

.SubTitleOfferta{
    font-size: 13px;
    margin-top: 2px;
    font-weight: normal;
}
