.NavigationBarAdmin {
    display: flex;
    flex-direction: column;
    background-color: var(--blue);
    width: 15%;
    height: calc(60vh);
    margin-left: 1%;
    padding: 1%;
    border-radius: 28px;
}

.NavigationElementAdmin {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Montserrat", serif;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 5px;
    transition: 250ms height ease-in-out;
    height: min-content
}

#LogPanelQuery{
    width: 30%;
    height: 40vh;
    gap: 2%;
}
#LogTitleQuery{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
#LogTitleQuery h1 h2{
    margin:0;
}

#LogTable{
    width: 100%;
    overflow-y: scroll;
    align-items: center;
    min-height: 60%;
}

#TableDiv{
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 2%;

}

#pageButton{
    position: absolute;
    top: 80%;
    right: 0%;

}

#pageButtonPrec{
    position: absolute;
    top: 80%;
    left: 0%;
}
table{
    border: none;
    border-radius: 28px;
    gap: 0
}

#LogTable {
    border-radius: 20px;
    min-height: 60%;
    color: white;
    font-weight: bolder;
    position: block;    
}

#LogTable thead > tr > td{
    background-color: var(--blue);
    
}

#LogTable tr {
    max-height: 3.3em; /* Altezza minima per tutte le righe */
}

#NrLog{
    color: var(--orange);
    font-weight: bold;
    font-size: 1.1rem;
    position: absolute;
    top: 85%;
}

#LogTable tbody > tr{
    max-height: 20%;
    font-weight: bold;
    color: var(--orange);
    
}



#LogTable td{
    color: inherit;
    border-bottom: 2px solid var(--blue);
    text-align: center;
}

#LogTable tbody > tr > td:nth-child(1){
    border-left: 2px solid var(--blue);
}

#LogTable tbody > tr > td:nth-child(6){
    border-right: 2px solid var(--blue);
}


#LogTable thead > tr > td:first-child{
    border-top-left-radius: 28px;
}

#LogTable thead > tr > td:last-child{
    border-top-right-radius: 28px ;
}

#LogTable tbody > tr:last-child > td:first-child{
    border-bottom-left-radius: 28px;
} 

#LogTable tbody > tr:last-child > td:last-child{
    border-bottom-right-radius: 28px;
}

@media only screen and (max-width: 600px) {
    .NavigationBarAdmin {
        width: 80%;
        height: fit-content;
    }

    .FlexRow {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        row-gap: 3vh;
    }

    .ColumnFlex {
        display: flex;
        width: 100vw;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        row-gap: 5vh;
    }

    .Panel {
        padding: 5%;
        width: 80vw !important;
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }

    #AdminBackButton {
        display: none;
    }

    #WaitingContracts{
        width: 95vw !important;
        height: 25vh !important;
        max-height: 25vh !important;
    }

    #ContainerComponent{
        width: 95vw !important;
    }

    #RegisterContainer{
        width: 95vw !important;
        height: 100vh !important;
    }
    .Table {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Request {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #PannelloRichieste{
        display: flex;
        flex-direction: column;
        width: 95vw !important;
        height: 55vh !important;
        max-height: 55vh !important;
        
    }

}