.ColumnFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 111px - var(--Footer));
}
.Panel{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
}

.Table{
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 70%;
    min-height: 70%;
    border-top: 0;
    border-radius: 28px;
}

.TableHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(9,9,9,.1);
    height: 15%;
}
.TableElementHeader, .RecordElement{
    text-align: center;
    font-size: large;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(9,9,9,.1);
    border-left: 1px solid rgba(9,9,9,.1);
}

.TableElementHeader{
    border-left: 0;
    border-right: 0;
    color: var(--orange);
    font-weight: 600;
}

.TableContent{
    width: 100%;
    overflow-y: auto; 
    overflow: auto;
    height: 85%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 0 0 28px 28px;
    row-gap: 10px;
}

.RecordElement{
    border: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    position: relative;
    
}

.Record{
    width: calc(100%);
    height: 10%;
    display: flex;
    border-radius: 28px;
    font-size: large;
    padding: px;
}

.deleteCheck{
    position: absolute;
    left: 0;
    margin: 15%;
    transform: scale(1.1) !important;
}
