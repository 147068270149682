.Page{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3vh;
}
.PanelReseller{
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
    row-gap: 2vh;
    padding: 1%;
}
.subtitle{
    width: 100%;
    display: flex;
    justify-content: left;
    padding-left: 5%;
    font-size: small;
    color: #666;
}
.RowContainerReseller{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 95%;
    align-items: center;
    column-gap: 2%;
    margin-left: 2.5%;

}
.PanelTitle{
    font-size: large;
    font-weight: 600;
}
.TextFieldReseller{
    width: 44%;
}
.RagioneSociale{
    width: 65%;
}

.PartitaIVA{
    width: 25%;
}
.NumerodicontattoReferente{
    width: 50%;
}
.CognomeReferente{
    width: 30%;
}
.NomeReferente{
    width: 30%;
}
.Via{
    width: 35%;
}
.Citta{
    width: 30%;
}
.Civico{
    width: 15%;
}
.CAP{
    width: 15%;
}
.ProvinciaNascita{
    width: 30%;
}
.DataScadenza,.DataEmissione{
    width: 50%;
}
.Personalized{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2%;
    padding: 20px;
}
.FileBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:3.5vh;
    padding: 15px;
    padding-top: 0;
}
.FileTitle{
    font-size: large;
    font-weight: 500;
    margin-left: 10%;
}
.ButtonCp{
    position: absolute;
    top: 141px;
    right: 4%;
    width: 15%;
}
.Mui-checked{
    color: var(--orange) !important;
}
.Altro{
    width: 90%;
    padding: 0% 7% 0% 7%;
}
.ComuneNascita{
    width: 45%;
}
#Voip{
    width: 80%;
    margin-left: 10%;
}
#Conn{
    width: 35%;
    margin-left: 0%;
    padding: 3%;
}
#Conn2{
    width: 35%;
}
#ResellerSend{
    width: 30%;
}
#RowConn{
    column-gap: 20%;
}

@media only screen and (max-width: 600px) {
    .Page{
        row-gap: 2vh;
    }
    .PanelReseller{
        width: 90%;
        padding-bottom: 5%;
    }
    #LogOut{
        display: none;
    }/*attivare bottoni*/
    .ButtonCp{
        display: flex;
        display: none;
    }
    .RowContainerReseller
    {
        flex-direction: column;
        align-items: center;
        row-gap: 2vh;
    }
    .NomeReferente,.CognomeReferente,.NumerodicontattoReferente,.PartitaIVA,.RagioneSociale,.Via,.Citta,.Civico,.CAP,.ProvinciaNascita,.DataScadenza,.DataEmissione,.ComuneNascita,.Personalized,.FileBox,.ButtonCp,.Altro,.Mui-checked,.FileTitle,.PanelTitle,.TextFieldReseller,.subtitle,.Page,.ResellerSend,.Conn,.Conn2,.Voip,.RowConn{
        width: auto;
    }
    .NumeroVoip{
        width: 80% !important;
    }
    #Conn, #Voip{
        width: 90%;
    }
    #Voip{
        margin-left: 5%;
    }
    #Conn2 {
        width: 80%;
    }
    .SubTitle{
        width: 40%;
    }
    .FileBox{
        padding: 0;
    }
    .ComuneNascita{
        width: 90%;
    }
    .Citta{
        width: 83%;
    }
    .FlexRow{
        padding: 4%;
        padding-left: 0;

    }
    .PanelTitle{
        padding: 3%;
        padding-bottom: 0;
    }
    #ResellerSend{
        width: 60%;
    }
}