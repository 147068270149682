.Request {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 6.5vh;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(9, 9, 9, .1);
    min-height: 6.5vh;
}

.RequestDate,
.RequestName,
.RequestSurname,
.RequestStatus {
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.RequestStatus{
    font-size: 15px;
    font-weight: 600;
}

.RequestNote{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    word-break: break-all;

}

.ReqElemnt {
    width: 20%;
    height: 6.5vh;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
    flex-grow: 100;
    text-align: center;
}

.LabelDate {
    width: 30%;
    color: var(--orange);
    font-size: 16px;
    font-weight: 600;
}
.InternalRequest{
    display: flex;
    width: 100%;
    height: 6.5vh;
}
.NotDisplay{
    display: none;
}

.InternalExpand{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding-bottom: 1%;
    transition: visibility 0s, opacity 0.5s linear;
}

#Btn{
    margin: 0
}

#expand {
    min-width: 3vh;
    max-width: 3vh;
    height: 6.5vh;
    max-height: 6.5vh;
    margin: 0;
    padding-left: 0;

}
@media only screen and (max-width: 600px) {
    #ResellerContainer{
        width: 90vw !important;
    }
    #DataViewer{
        width: 90vw !important;
    }
}