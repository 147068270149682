dialog{
    outline: none !important;
}
.popup{
    position: fixed; 
    width: 35%; /* Need a specific value to work */
    height: 25%;
    background-color: white;
    z-index: 2;
    border: 0;
    border-radius: 28px;
    padding: 0;
}
.popup:focus{
    outline: none;
}
.popuptitlebox{
    width: 100%;
    height: 25%;
    background-color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 28px 28px 0 0;
    padding: auto;
}
.FlexContainerPopup{
    display: flex;
    width: 100%;
    height: 75%;
    align-items: center;
    column-gap: 0;
}
.ColumnFlexContainerPopup{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    column-gap: 0;
}
.DataViewerContainer{
    position: absolute;
    width: 40%;
    overflow: hidden;
    height: 40%;
    z-index: 2;
    padding: 2.5%;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
}
.DataViewer{
    width: 95%;
    height: 95%;
}
.DataViewerTitle{
    font-size: 25px;
    color: var(--orange);
    font-weight: 600;
}

.DataViewerKey{
    font-size: 20px;
    color: var(--blue);
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: auto;
}

.DataViewerElement{
    width: 100%;
    height: 100%;

}
.DataViewerList{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow-y: auto;
}
.DataViewerValue {
    display: inline-flex;
    font-size: 18px;
    color: black;
    font-weight: 600;
    margin-left: 5%;
    margin-right: 5%;
    max-width: 75%;
    overflow-x: auto;
}
.ButtonContainerDataViewer{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5%;
    border-radius: 18px;
    background-color: var(--orange);
    right: 5%;
    cursor: pointer;
}
.ImageDataViewer{
    width: 100%;
    height: 100%;
;
}
