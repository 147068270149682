.FooterContainer{
    height: var(--Footer);
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0;
    padding: 0;
    /*background-image: linear-gradient(100deg,#150849 0%,rgba(0,0,0,0) 100%);*/
    background-color: #201549 ;
    margin-top: auto;
}
.FooterLogo{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.FooterDesc{
    height: 100%;
    width: 100%;
}
.FooterDesc{
    margin: 10px;
    color: white;
}
.FooterImg{
    margin-left: 20px;
    width: 90%;
}
.Footerp{
    font-size: 15px;
    margin: 5px;
}
.Footerh{
    font-size: 25px;
    margin-bottom: 10px;
}
@media screen and (max-width: 600px){
    .FooterContainer{
        flex-direction: column;
        height: fit-content;
        padding-top: 25px;
    }
    .FooterDesc{
        text-align: left;
    }
    .Footerp{
        font-size: 20px;
        margin-left: 15px;
    }
    .Footerh{
        font-size: 26px;
        margin-left: 5px;

    }
    .FooterLogo{
        width: auto;
        margin: 0;
    }

}