.rowContainer{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-between;
    width: 100%;
}
.Form{
    width: calc(80% * 0.6);
}
.basicFlexColumn{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}
.Panel{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
}
#Cap, #Provincia{
    width: 15%;
}

#File0Res, #File1Res{
    color: var(--blue);
    font-weight: 800;
    font-size: 22px;
}
#Label0Res, #Label1Res{
    font-weight: 800;
    color: var(--blue);

}
#LogOut{
    position: absolute;
    top: 141px;
    left: 4%;
    width: 15%;
}
.BackButton{
    position: absolute;
    top: 141px;
    right: 4%;
    width: 15%;
}
#AdminBackButton{
    position: absolute;
    top: 141px;
    right: 1.5%;
    width: 13%;
}

@media screen and (max-width: 800px){
    .Form{
        width: 95%;

    }
    #Provincia{
        width: 30%;
    }
    #Cap {
        width: 40%;
    }

    .btn{
        transform: scale(1.2);
        width: 80%;
    }
    
    .MuiFormHelperText-root{
        width: 200%;
    }

    #Intern{
        padding: 0px !important;
        width: 90vw;
        padding: 10px;
    }
    #IpStaticilabel{
        display: none;
    }
}
input[type=checkbox]{
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 10px;
    accent-color: var(--orange);
}
.SliderContainer{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 95%;
    column-gap: 10px;
    height: 100%;
    overflow-x: auto;
    margin-left: 2.5%;
}
.ButtonAddField{
    height: 56px;
    width: 50px;
    background-color: white;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}
.desc{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.titledialog{
    color: white;
    font-size: 35px;
    font-weight: 900;
}
.Marked{
    color: var(--orange);
    font-weight: 900;
    font-size: larger;
}
.SimplePar{
    font-size: 1rem;
    margin: 0px;
    line-height: 1.66;
    letter-spacing: 0.0333em;
}
.DownloadButton{
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 28px;
    margin: 0;
}