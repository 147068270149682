@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Kufi+Arabic:wght@100..900&display=swap');

:root{
    --orange: #F3440E;
    --blue: #201549;
    --Footer: 200px
}

@media screen and (min-width: 800px) and (max-width: 1500px)  {
    :root{
        --Footer: 18vh
    }
}

::selection{
    background-color: var(--orange);
    color: white;
}

::-moz-selection { /* Code for Firefox */
    background-color: var(--orange);
    color: white;
  }

.Home{
    width: 100vw;
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
    
}

.HomePanelTitle > span{
    font-weight: 500;
    font-size: 60px;
}

.HomePanelTitle:hover{
    transform: scale(1.05);
    background-color: rgba(243, 68, 14, 1);
}
.HomePanelTitle{
    font-size: 34px;
    font-family: 'Kanit';
    background-color: rgba(243, 68, 14, 0.8);
    color: white;
    border-radius: 28px;
    width: 30%;
    height: 50%;
    transition: transform .2s;
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.4);
    cursor: pointer;
}
.HomePanelDescription{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
    .Home{
        flex-direction: column;
        row-gap: 10%;
        height: fit-content;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .HomePanelTitle{
        height: 300px;
        width: 95%;
        margin: 10px;
    }

    .HomePanelTitle > span{
        font-size: 40px;
    }
    .HomePanel{
        width: 80%;
    }
}
@media only screen and (max-width: 900px) and (min-width: 600px){
    .Home{
        flex-direction: column;
        row-gap: 10%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .HomePanelTitle{
        width: 60%;
        height: 40%;
    }
}
::-webkit-scrollbar {
    width: 5px;
    border-radius: 40px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #F2F2F2;
    border-radius: 27px;

  }
  
  ::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    border-radius: 27px;

  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #6E6E6E;
    border-radius: 27px;

  }