.NavContainer{
    height: 100px;
    background-color: var(--blue);
    display: flex;
    flex-direction: row;  
    justify-content: space-between; 
    transition: 250ms height  ease-in-out;
    padding-bottom: 5px;
    padding-top: 5px;
    
}
.NavLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: 250ms height  ease-in-out;
    color: white;
    font-weight: bolder;
}
.NavLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    transition: 250ms height  ease-in-out;
}
.Link{
    margin: 10px;
    color: white;
    font-family: "Montserrat", serif;
    transition: 250ms height  ease-in-out;
    cursor: pointer;
    font-size: 1.3rem;
}
@media screen and (max-width: 600px){
    .NavLinks{
        flex-direction: column;
    }
    .Link{
        font-size: 25px;
        color: var(--blue);
    }
    .NavContainer{
        flex-direction: column;
    }
}