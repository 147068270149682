.panel{
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    width: calc(80% * 0.6);
    height: 50%;
    border: 1px solid rgba(9,9,9,.1);
    border-radius: 28px;
    column-gap: 15px;
    margin: 10px;
    align-items: center;
}
.FlexRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-right: 20px;
}
.App{
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 6px;
}
.Title{
    color: #F3440E;
    font-weight: 600;
    font-size: 50px;
    font-family: "Montserrat", serif;
    line-height: 1.2;
    margin: 10px;
}
.MuiInputBase-root{
    border-radius: 28px !important;
    font-size: larger !important;
}

.InternalPanel{
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
    margin: 10px;
    align-items: center;

}
.SubTitle{
    font-size: larger;
}
body{
    font-family: "Montserrat", serif;
}
.btn{
    border-radius: 28px;
    border-color: rgba(0, 0, 0, 0.23);
    height: 60.6px;
    margin-bottom: 60.6px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    background-color: white;
    width: 60%;
    color: rgb(243, 68, 14);

}
.btn:hover{
    background-color: #f1f1f1;
}
.btn:disabled{
    cursor: not-allowed;
    color: rgba(243, 68, 14,0.4);
    border-color: rgba(9,9,9,.1);
}
.Row{
    width: 48%;
}
input[type="file"] {
    display: none;
}
.fileInput{
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    height: 47px;
    font-size: 25px;
    border-radius: 28px;
}
.fileInput:hover{
    background-color: #f1f1f1;
}
.MuiLinearProgress-bar{
    background-color: #F3440E !important;

}
@media only screen and (max-width: 600px) {
    .panel{
        flex-direction: column;
        min-height:100%;
        width: 80%;
        
    }
    .App{
        height: 100%;
        overflow-y: scroll;
        
    }
    .Row{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 48%;
    }
    .InternalPanel{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Title{
        font-size: 30px;
    }
    .btn{
        width: 60%;
        height: 60px;
        scale: 0.9;
    }
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
    .panel{
        width: 80%;
    }
}